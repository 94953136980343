import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import UIMaxWidth from '../Layout/MaxWidth';
import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';

const Header = styled('h1')`
  font-size: 1.6875rem;
  font-weight: 600;
  margin: 1rem 0 3rem;
`;

const Content = styled('div')`
  padding-bottom: 1rem;

  p {
    margin-bottom: 1rem;
  }
`;

const MaxWidth = styled(UIMaxWidth)``;

const ContentPage = ({ page, result }) => (
  <>
    <Header>
      <MaxWidth>
        {page ? (
          result.data.route.object.name
        ) : (
          <LoadingLine heightPx={27} widthRem={15} />
        )}
      </MaxWidth>
    </Header>
    <MaxWidth>
      {page ? (
        <>
          <Content
            dangerouslySetInnerHTML={{
              __html: result.data.route.object.content
            }}
          />
        </>
      ) : (
        <LoadingLine
          heightPx={14}
          widthRem={30}
          randomizeWidthBy={10}
          count={10}
          className={css`
            margin-bottom: 12px;
          `}
        />
      )}
    </MaxWidth>
  </>
);

export default ContentPage;
